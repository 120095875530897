@import '../../../../assets/sass/variables';

.component {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.row {
	padding: 0 20px;
}
