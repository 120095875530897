@import '../../../../assets/sass/variables';

@keyframes egg-appearing {
	100% {
		transform: translateY(-30px);
		opacity: 1;
	}
}

.component {
	height: var(--welcome-height);
	display: grid;
	align-items: center;
	align-content: center;
	justify-content: center;
	justify-items: center;
	text-align: center;
	background: #0B0B0B;
	position: relative;
	overflow: hidden;
	gap: 24px;
	cursor: url(../images/cursor.png), pointer;
}

.title {
	font-size: 48px;
	font-weight: 700;
	color: #FFD754;
	padding: 0 25px;

	@include for-tablet-portrait-up {
		font-size: 28px;
	}
}

.code {
	@include for-tablet-portrait-up {
		width: 96px;
		height: 96px;
	}
}

.subtitle {
	color: #AAA28D;
	font-size: 14px;
	padding: 0 25px;
}

.canvas {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}

.egg {
	width: 290px;
	height: 336px;
	position: absolute;
	left: 0;
	bottom: -130px;
	right: 0;
	margin: auto;
	pointer-events: none;
	z-index: 10;
	background: url(../images/egg.png) no-repeat;
	background-size: 100%;
	opacity: 0;
	animation: egg-appearing .3s 1 forwards;

	@include for-tablet-portrait-up {
		bottom: -200px;
	}
}

.eyes {
	width: 166px;
	height: 72px;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	background: url(../images/eyes.png) no-repeat;
	background-size: 100%;
	top: 90px;
}

.eye {
	width: 60px;
	height: 60px;
	position: absolute;
	top: 0;
	border-radius: 60px;

	&:first-child {
		left: 3px;
	}

	&:last-child {
		right: 3px;
	}
}

.eyeInner {
	width: 25px;
	height: 25px;
	background: url(../images/eye-inner.png) no-repeat;
	background-size: 100%;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	transition: transform .15s cubic-bezier(0.28, 0.07, 0.21, 0.87);
}

.rocketTail {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	width: 100%;
	height: 100%;

	@include for-phone-only {
		display: none;
	}
}
