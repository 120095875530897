@import '../../../../assets/sass/variables';

.component {
	width: 100%;
	position: relative;
	overflow: hidden;

	&::before {
		width: 154px;
		height: 149px;
		content: '';
		position: absolute;
		right: 0;
		top: -25px;
		background: url(../images/eggs.png) no-repeat;
		background-size: 100%;
	}
}

.top {
	display: grid;
	gap: 2px;
	line-height: 1.2;
	padding: 14px 20px 0 20px;
}

.title {
	padding-left: 34px;
	color: #fff;
	font-size: 24px;
	font-weight: 700;
	letter-spacing: -0.4px;
	background: url(../images/tasks-icon.svg) 0 50% no-repeat;
	background-size: 24px;
}

.subtitle {
	color: #BAB7AE;
	font-size: 14px;
}

.middle {
	width: 100%;
}

.bottom {
	padding: 0 20px;
}
