@import '../../../../assets/sass/variables';

@keyframes navigation-appearing {
  100% {
    transform: translateY(-30px);
    opacity: 1;
  }
}

.component {
  width: calc(100% - 40px);
  padding: 10px;
  border-radius: 40px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  background: linear-gradient(180deg, #1D1E22 0%, #4B484F 100%);
  top: 30px;
  opacity: 0;
  animation: navigation-appearing 1.5s cubic-bezier(0.28, 0.07, 0.21, 0.87) 1 forwards;

  &::before {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: linear-gradient(180deg, #1B1D22 0%, #3D3C46 100%);
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: inherit;
  }
}

.list {
  display: grid;
  grid-auto-flow: column;
}

.item {
  display: grid;
}

.button {
  height: 56px;
  display: grid;
  justify-items: center;
  align-items: center;
  align-content: center;
  border-radius: 56px;
  padding: 0 10px;
  gap: 4px;
  letter-spacing: -0.4px;
  position: relative;
  transition: filter .3s;

  &:hover {
    filter: brightness(1.2);
  }

  &.active {
    background: #FFD754;
    color: #000;
  }

  &.friends {
    gap: 8px;
    padding: 0 20px;
    grid-auto-flow: column;
    justify-content: center;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &::before {
    width: 7px;
    height: 7px;
    content: '';
    position: absolute;
    top: 5px;
    right: -35px;
    left: 0;
    margin: auto;
    border-radius: 7px;
    background: #f00;
    transition: opacity .3s;
    opacity: 0;
  }

  &.new::before {
    opacity: 1;
  }
}

.icon {
  width: 28px;
  height: 28px;

  path {
    transition: fill .3s;
    fill: #C0BBA7;

    .active & {
      fill: #000;
    }
  }
}

.text {
  display: grid;
  justify-content: left;
  justify-items: left;
}

.counter {
  min-width: 30px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.subtitle {
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
}

.title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  color: #9A9685;
}
