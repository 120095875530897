@import '../../../../../assets/sass/variables';

.component {
  display: grid;
  padding: 20px 20px 0 20px;
  justify-items: center;
  gap: 5px;
}

.counter {
  min-width: 150px;
  font-weight: 700;
  font-size: 48px;
  color: #fff;
}

.bonus {
  min-width: 100px;
  color: #FFD754;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  padding-right: 20px;
  background: url(../images/egg.png) right 50% no-repeat;
  background-size: 16px;
}
