@import '../../../assets/sass/variables';

.row {
	width: 100%;
	position: relative;
	display: grid;
}

.field {
	width: 100%;
	background: #161615;
	height: 54px;
	border-radius: 54px;
	padding: 0 120px 0 16px;
	font-size: 15px;
	color: #FFD754;
	font-weight: 500;

	&::placeholder {
		font-weight: 400;
		color: #79776F;
	}
}

.button {
	width: fit-content;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 9px;
	padding: 0 14px;
	height: 36px;
	background: #FFD754;
	color: #000;
	font-size: 14px;
	font-weight: 700;
	transition: filter .3s, background-color .3s;
	border-radius: 36px;

	&:hover {
		filter: brightness(1.1);
	}

	&.disabled {
		pointer-events: none;
		background: #5C5028;
	}
}
