@import '../../../../assets/sass/variables';

@keyframes invite-appearing {
	100% {
		opacity: 1;
	}
}

@keyframes info-appearing {
	100% {
		transform: translateY(-20px);
		opacity: 1;
	}
}

.component {
	width: calc(100% - 40px);
	display: grid;
	justify-items: center;
	gap: 10px;
	position: absolute;
	bottom: 110px;
	z-index: 40;
	left: 20px;
	opacity: 0;
	transition: transform .6s cubic-bezier(0.28, 0.07, 0.21, 0.87);
	animation: invite-appearing 1.5s cubic-bezier(0.28, 0.07, 0.21, 0.87) 1 forwards;

	&.friends {
		transform: translateY(72px);
	}

	&.main {
		transform: translateY(-10px);
	}

	&.holders {
		transform: translateY(48px);
	}

	&.gems, &.tasks {
		animation: none;
		pointer-events: none;
		visibility: hidden;
	}
}

.row {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 10px;

	.tasks & {
		gap: 0;
		grid-template-columns: 1fr;
	}
}

.button {
	height: 64px;
	border-radius: 32px;
	padding: 0 30px;
	color: #000;
	font-size: 18px;
	font-weight: 700;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	display: grid;
	position: relative;
	background: linear-gradient(180deg, #71BD14 0%, #C3FF54 100%);
	transition: filter .3s, width .6s;

	&:hover {
		filter: brightness(1.1);
	}

	.friends &, .holders &, .tasks & {
		width: 100%;
	}
}

.tasksButton {
	height: 64px;
	display: grid;
	align-items: center;
	padding: 0 22px;
	border-radius: 32px;
	position: relative;
	background: linear-gradient(180deg, rgba(113, 189, 20, 0.36) 0%, rgba(195, 255, 84, 0.50) 100%);
	transition: filter .3s;

	&:hover {
		filter: brightness(1.2);
	}

	&::before {
		width: 100%;
		height: 100%;
		border-radius: inherit;
		position: absolute;
		left: 0;
		top: 0;
		content: '';
		border: 2px solid rgba(195, 255, 84, 0.12);
		pointer-events: none;
	}

	&::after {
		width: 20px;
		height: 20px;
		content: '';
		right: -2px;
		top: -2px;
		position: absolute;
		z-index: 2;
		border-radius: 20px;
		border: 4px solid #0B0B0B;
		background: #FF0000;
	}

	span {
		padding-right: 34px;
		background: url(../images/tasks-icon.svg) 100% 50% no-repeat;
		background-size: 24px;
		color: #C3FF54;
		font-size: 18px;
		font-weight: 700;
		position: relative;
		z-index: 2;
	}

	.tasks & {
		display: none;
	}
}

.yellow {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #FFD754;
	display: grid;
	align-items: center;
	justify-content: center;
	transition: opacity .3s;
	opacity: 0;

	.holders & {
		opacity: 1;
	}
}

.title {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 34px;
	background: url(../images/icon.svg) right 50% no-repeat;
	background-size: 24px;
}

.info {
	font-weight: 300;
	font-size: 10px;
	text-transform: uppercase;
	color: #9A9685;
	opacity: 0;
	position: relative;
	top: 20px;
	animation: info-appearing 1.5s cubic-bezier(0.28, 0.07, 0.21, 0.87) 1 forwards;
	transition: font-size .3s;

	.holders & {
		font-size: 12px;
	}

	strong {
		font-weight: 700;
		color: #CCC5A7;
	}
}
