@import "../../../../../assets/sass/variables";

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #fff;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 #fff;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 #fff;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

.component {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #fff;
  color: #fff;
  box-shadow: 9999px 0 0 0 #fff;
  animation: dot-falling 1s infinite linear;
  animation-delay: .1s;

  &::before, &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #fff;
    color: #fff;
    animation: dot-falling-before 1s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #fff;
    color: #fff;
    animation: dot-falling-after 1s infinite linear;
    animation-delay: .2s;
  }
}
