@import '../../../../../assets/sass/variables';

.component {
	overflow: hidden;
	margin-top: 14px;
	position: relative;

	::-webkit-scrollbar-track {
		margin: 25px 0;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #62625D;
		border-radius: 6px;
		margin: 20px 0;
	}
}

.wrapper {
	width: calc(100% - 20px);
	overflow-y: auto;
	padding: 0 7px 0 10px;
	margin: 0 auto;
	-webkit-overflow-scrolling: touch;
	position: relative;
}

.shadows {
	pointer-events: none;
}

.shadow {
	width: 100%;
	height: 50px;
	position: absolute;
	left: 0;
	z-index: 10;
	opacity: 0;
	transition: opacity .3s;

	&::before, &::after {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		content: '';
		background: linear-gradient(0deg, rgba(11,11,11,1) 0%, rgba(11,11,11, 0) 100%);
	}

	&:first-of-type {
		top: 0;

		&::before, &::after {
			transform: rotate(180deg);
			top: 0;
		}
	}

	&:last-of-type {
		bottom: 0;
		opacity: .7;

		&::before, &::after {
			bottom: 0;
		}
	}

	.scrolling &:first-of-type {
		opacity: .7;
	}

	.scrolled &:last-of-type {
		opacity: 0;
	}
}

.list {
	display: grid;
	gap: 2px;
	padding-bottom: 12px;
	border-radius: 12px;
	background: #0B0B0B;
}

.item {
	height: 56px;
	padding: 0 46px 0 12px;
	border-radius: 14px;
	background: #212120;
	display: grid;
	align-items: center;
	gap: 8px;
	position: relative;
	grid-template-columns: auto 1fr;
	overflow: hidden;
}

.empty {
	padding: 40px;
	display: grid;
	align-items: center;
	text-align: center;
	background: #191818;
	border-radius: 20px;
	border: 1px solid #2b2b2b;
	color: #9f9f9f;
}
