@import '../../../assets/sass/variables';

.row {
	width: 100%;
	position: relative;
	display: grid;
}

.field {
	width: 100%;
	background: #161615;
	height: 54px;
	border-radius: 54px;
	padding: 0 120px 0 58px;
	font-size: 15px;
	display: grid;
	align-items: center;
	font-weight: 400;
	color: #79776F;
}

.preview {
	width: 36px;
	height: 36px;
	border-radius: 36px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 9px;
	margin: auto;
	background: #FFD754 url(../assets/images/uploader.svg) 50% no-repeat;
	background-size: 18px;
	overflow: hidden;

	&.hasAvatar {
		background: #FFD754;
	}
}

.button {
	width: fit-content;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 9px;
	padding: 0 14px;
	height: 36px;
	background: #FFD754;
	color: #000;
	font-size: 14px;
	font-weight: 700;
	transition: filter .3s, background-color .3s, color .3s;
	border-radius: 36px;
	overflow: hidden;

	&:hover {
		filter: brightness(1.1);
	}

	&.disabled, &.uploading {
		pointer-events: none;
		background: #5C5028;
	}
}

.currentImage {
	width: 36px;
	height: 36px;
	border-right: 36px;
	position: absolute;
	left: 0;
	top: 0;
}

.input {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 2;
}

.loaderWrapper {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #5C5028;
	border-radius: 8px;
	visibility: hidden;
	opacity: 0;
	transition:
			opacity 0.3s ease,
			visibility 0.3s ease;

	.uploading & {
		visibility: visible;
		opacity: 1;
	}
}

.loader {
	div {
		background-color: #000 !important;
	}
}
