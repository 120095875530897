@import '../../../../../assets/sass/variables';

.component {
  width: fit-content;
  margin-top: 8px;
  min-width: 140px;
  color: #FFD754;
  line-height: 1.2;
  font-size: 17px;

  strong {
    font-size: 28px;
    font-weight: 700;
    position: relative;
    top: 2px;
  }
}
