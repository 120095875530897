@import '../../../../assets/sass/variables';

@keyframes blinking {
	25%, 75% {
		opacity: .5;
		transform: scale(.8);
	}

	50%, 100% {
		opacity: 1;
		transform: scale(1);
	}
}

.component {
	width: 100%;
	position: relative;
	padding: 26px;
	background: #292928;
	border-radius: 32px 32px 0 0;
}

.top {
	display: grid;
	gap: 8px;
}

.title {
	font-weight: 700;
	font-size: 18px;
	color: #fff;
	padding-left: 32px;
	background: url(../images/icon.svg) 0 50% no-repeat;
	background-size: 24px;
}

.subtitle {
	color: #BAB7AE;
	font-size: 14px;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	transition: filter .3s;

	&:hover {
		filter: brightness(1.2);
	}
}

.balance {
	margin-top: 25px;
	display: grid;
}

.balanceTitle {
	font-weight: 700;
	font-size: 18px;
	color: #fff;
	padding-left: 32px;
	background: url(../images/balance-icon.svg) 0 50% no-repeat;
	background-size: 24px;
}

.balanceSubtitle {
	color: #BAB7AE;
	font-size: 14px;
	line-height: 1.2;
	margin-top: 4px;
}

.list {
	margin-top: 9px;
	display: grid;
}

.item {
	display: grid;

	&.disabled {
		pointer-events: none;
	}
}

.itemRow {
	display: grid;
	justify-items: left;
}

.itemSubtitle {
	color: #BAB7AE;
	font-size: 12px;
	line-height: 120%;
}

.itemButton {
	display: grid;
	border-bottom: 1px solid #333332;
	height: 44px;
	align-items: center;
	padding-left: 28px;
	position: relative;
	gap: 24px;
	grid-template-columns: 1fr auto;
}

.itemTitle {
	display: grid;
	grid-auto-flow: column;
	justify-content: left;
	align-items: center;
	color: #fff;
	font-size: 14px;
	gap: 8px;
	font-weight: 600;

	.link & {
		position: relative;
		padding-right: 10px;

		&::before {
			width: 6px;
			height: 6px;
			background: #FF4928;
			position: absolute;
			right: 0;
			top: 2px;
			content: '';
			border-radius: 6px;
			animation: blinking 4s linear infinite;
		}
	}
}

.itemIcon {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	margin: auto;
}

.itemIcon, .itemArrow {
	width: 20px;
	height: 20px;
}
