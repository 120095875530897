@import '../../../../assets/sass/variables';

.component {
	width: 100%;
	position: relative;
	padding: 26px;
	background: #292928;
	border-radius: 32px 32px 0 0;
}

.top {
	display: grid;
	gap: 8px;
}

.title {
	font-weight: 700;
	font-size: 18px;
	color: #fff;
	padding-left: 32px;
	background: url(../images/icon.svg) 0 50% no-repeat;
	background-size: 24px;
}

.subtitle {
	color: #BAB7AE;
	font-size: 14px;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	transition: filter .3s;

	&:hover {
		filter: brightness(1.2);
	}
}

.form {
	display: grid;
	gap: 8px;
	margin-top: 16px;
	padding-bottom: 300px;
}

.row {
	width: 100%;
	position: relative;
	display: grid;
}

.field {
	width: 100%;
	background: #161615;
	height: 54px;
	border-radius: 54px;
	padding: 0 120px 0 16px;
	font-size: 15px;
	color: #FFD754;
	font-weight: 500;

	.avatar & {
		display: grid;
		align-items: center;
		padding-left: 58px;
	}

	&::placeholder, .avatar & {
		font-weight: 400;
		color: #79776F;
	}
}

.preview {
	width: 36px;
	height: 36px;
	border-radius: 36px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 9px;
	margin: auto;
	background: #FFD754 url(../images/uploader.svg) 50% no-repeat;
	background-size: 18px;
}

.button {
	width: fit-content;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 9px;
	padding: 0 14px;
	height: 36px;
	background: #FFD754;
	color: #000;
	font-size: 14px;
	font-weight: 700;
	transition: filter .3s;
	border-radius: 36px;

	&:hover {
		filter: brightness(1.1);
	}
}
