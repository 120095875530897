@import '../../../../assets/sass/variables';

.component {
	width: 100%;
	position: relative;
	overflow: hidden;

	&::before {
		width: 120px;
		height: 120px;
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		background: url(../images/hand.png) no-repeat;
		background-size: 100%;
	}
}

.top {
	display: grid;
	padding: 30px 20px 0 20px;
}

.tabs {
	width: fit-content;
	display: grid;
	height: 54px;
	grid-auto-flow: column;
	gap: 6px;
	padding: 6px;
	position: relative;
	border-radius: 54px;
	background: linear-gradient(180deg, #1D1E22 0%, #4B484F 100%);

	&::before {
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		background: linear-gradient(180deg, #1B1D22 0%, #3D3C46 100%);
		content: "";
		position: absolute;
		left: 2px;
		top: 2px;
		border-radius: inherit;
	}
}

.indicator {
	height: 42px;
	border-radius: 42px;
	background: #FFD754;
	position: absolute;
	top: 6px;
	left: 0;
	transition: width .3s, transform .3s;
}

.item {
	display: grid;
	position: relative;
	z-index: 2;

	&.active {
		pointer-events: none;
	}
}

.button {
	height: 42px;
	padding: 0 16px 0 14px;
	border-radius: 42px;
	display: grid;
	align-items: center;
	gap: 6px;
	grid-template-columns: 20px 1fr;
}

.tabsTitle {
	color: #BAB7AE;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: -0.4px;
	text-transform: uppercase;
	transition: color .3s;

	.active & {
		color: #000;
	}
}

.tabsIcon {
	width: 20px;
	height: 20px;

	path {
		fill: #BAB7AE;
		transition: fill .3s;
	}

	.active & path {
		fill: #000;
	}
}

.middle {
	width: 100%;
	mask-image: linear-gradient(180deg, #000 90%, transparent);
}

.bottom {
	padding: 0 20px;
}
