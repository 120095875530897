@import '../../../../assets/sass/variables';

.component {
	width: 100%;
	padding: 26px;
	background: #292928;
	display: grid;
	gap: 16px;
	position: relative;
	border-radius: 32px 32px 0 0;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	transition: filter .3s;

	&:hover {
		filter: brightness(1.2);
	}
}

.top {
	display: grid;
}

.balanceTitle {
	font-weight: 700;
	font-size: 18px;
	color: #fff;
	padding-left: 32px;
	background: url(../images/balance-icon.svg) 0 50% no-repeat;
	background-size: 24px;
}

.balanceSubtitle {
	color: #BAB7AE;
	font-size: 14px;
	line-height: 1.2;
	margin-top: 4px;
}

.list {
	display: grid;
}

.item {
	display: grid;
	font-size: 14px;
	font-weight: 600;
	grid-template-columns: 1fr auto;
	gap: 24px;
	border-bottom: 1px solid #333332;
	height: 44px;
	align-items: center;
}

.itemTitle {
	display: grid;
	grid-auto-flow: column;
	justify-content: left;
	align-items: center;
	color: #fff;
	gap: 8px;
}

.counter {
	color: #FFD754;
}
