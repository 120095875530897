@import '../../../../assets/sass/variables';

.component {
	width: 100%;
	padding: 26px;
	background: #292928;
	position: relative;
	border-radius: 32px 32px 0 0;

	&::before {
		width: 390px;
		height: 251px;
		content: '';
		position: absolute;
		top: -251px;
		left: 50%;
		transform: translateX(-50%);
		background: url(../images/window-bg.png) no-repeat;
		background-size: 100%;
		display: none;
	}

	&.win::before {
		display: block;
	}
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	transition: filter .3s;

	&:hover {
		filter: brightness(1.2);
	}
}

.top {
	display: grid;
	justify-items: center;
	text-align: center;
	gap: 8px;
}

.title {
	display: grid;
	padding-top: 112px;
	width: 100%;
	background-size: 96px;
	background-position: 50% 0;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 24px;
	font-weight: 700;
	line-height: 1.2;
	background-image: url(../images/lose-icon.png);

	.win & {
		background-image: url(../images/win-icon.png);
	}
}

.subtitle {
	color: #BAB7AE;
	font-size: 14px;
	line-height: 1.2;

	strong {
		color: #FFD754;
		font-weight: 600;
	}
}

.button {
	width: 100%;
	height: 64px;
	border-radius: 32px;
	padding: 0 44px;
	color: #000;
	font-size: 18px;
	font-weight: 700;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
	display: grid;
	position: relative;
	background: linear-gradient(180deg, #71BD14 0%, #C3FF54 100%);
	transition: filter .3s, width .6s;

	&:hover {
		filter: brightness(1.1);
	}
}
