@import '../../../../assets/sass/variables';

.component {
  width: 100%;
  padding: 26px;
  background: #292928;
  position: relative;
  border-radius: 32px 32px 0 0;
}

.top {
  display: grid;
  gap: 8px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  padding-left: 32px;
  background: url(../images/icon.svg) 0 50% no-repeat;
  background-size: 24px;
}

.subtitle {
  color: #BAB7AE;
  font-size: 14px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: filter .3s;

  &:hover {
    filter: brightness(1.2);
  }
}

.list {
  display: grid;
  gap: 12px;
  margin-top: 26px;
}

.item {
  gap: 4px;
  padding-bottom: 12px;
  border-bottom: 1px solid #333332;
  display: grid;
}

.itemTitle {
  font-weight: 600;
  color: #FFD754;
  font-size: 16px;
}

.text {
  color: #fff;
  font-size: 13px;
}
