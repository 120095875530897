@import '../../../../../assets/sass/variables';

.component {
	height: 56px;
	padding: 0 46px 0 54px;
	border-radius: 14px;
	background: #212120;
	display: grid;
	align-items: center;
	gap: 8px;
	position: relative;
	grid-template-columns: auto 1fr;
	overflow: hidden;
	transition: background-color .3s;

	&.done {
		background: rgba(33, 33, 32, 0.5);
		pointer-events: none;

		&::before {
			opacity: .5;
		}
	}

	&::before {
		width: 32px;
		height: 32px;
		border-radius: 8px;
		overflow: hidden;
		content: '';
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		margin: auto;
		background-repeat: no-repeat;
		transition: opacity .3s;
	}

	&.easy::before, &.medium::before, &.hard::before {
		background-size: 18px;
		background-position: 50%;
	}

	&.easy::before {
		background-color: #E3DABD;
	}

	&.medium::before {
		background-color: #FFD754;
	}

	&.hard::before {
		background-color: #91EC5F;
	}

	&.worldEgg::before, &.plgBet::before {
		background-size: 100%;
	}

	&.worldEgg::before {
		background-image: url(../images/worldegg.png);
	}

	&.plgBet::before {
		background-image: url(../images/plgbet.png);
	}

	&.friends::before {
		background-image: url(../images/friends.svg);
	}

	&.boxes::before {
		background-image: url(../images/boxes.svg);
	}

	&.wallet::before {
		background-image: url(../images/wallet.svg);
	}

	&:hover {
		background-color: #2B2B2B;
	}
}

.description {
	display: grid;
	gap: 4px;
	overflow: hidden;
	line-height: 120%;
	font-size: 13px;
	transition: opacity .3s;

	.done & {
		opacity: .5;
	}
}

.title {
	max-width: 100%;
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 600;
}

.reward {
	color: #FFD754;
	font-weight: 700;
	padding-right: 14px;
	background: url(../images/egg.png) 100% 50% no-repeat;
	background-size: 10px;
	width: fit-content;
}

.icon {
	width: 24px;
	height: 24px;
	top: 0;
	bottom: 0;
	right: 12px;
	margin: auto;
	position: absolute;
	transition: transform .3s, filter .3s;

	.component:hover & {
		transform: translateX(5px);
		filter: brightness(1.6);
	}

	.done & {
		width: 20px;
		height: 20px;
	}
}
