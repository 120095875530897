@import './variables';

.container {
  width: 100%;
  max-width: 1614px;
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  position: relative;
  padding: 0 25px;
}
