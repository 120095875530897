@import '../../../../assets/sass/variables';

@keyframes bouncing {
  25% {
    transform: scale(1.08);
  }

  50% {
    transform: scale(0.94);
  }

  75% {
    transform: scale(1.04);
  }

  100% {
    transform: scale(1);
  }
}

.component {
  width: 100%;
  padding: 44px 26px 26px 26px;
  background: #292928;
  border-radius: 32px 32px 0 0;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: filter .3s;

  &:hover {
    filter: brightness(1.2);
  }
}

.top {
  width: 100%;
  display: grid;
  gap: 8px;
  justify-items: center;
  text-align: center;
}

.title {
  width: 100%;
  padding-top: 112px;
  background: url(../images/icon.png) 50% 0 no-repeat;
  background-size: 96px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

.subtitle {
  color: #BAB7AE;
  font-size: 14px;
  line-height: 1.2;
}

.reward {
  margin-top: 8px;
  text-align: center;
  font-weight: 500;
  color: #FFD754;
  font-size: 14px;
}

.form {
  margin-top: 16px;
  display: grid;
  gap: 8px;
}

.field {
  width: 100%;
  display: grid;
  align-items: center;
  height: 67px;
  border-radius: 67px;
  background: #161615;
  color: #FFD754;
  font-size: 15px;
  text-align: center;
  padding: 0 16px;
  overflow: hidden;
  position: relative;

  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.button {
  height: 64px;
  border-radius: 55px;
  background: linear-gradient(180deg, #71BD14 0%, #C3FF54 100%);
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;
  grid-auto-flow: column;
  overflow: hidden;
  color: #000;
  font-size: 18px;
  gap: 10px;
  font-weight: 700;
  transition: filter .3s;

  &:hover {
    filter: brightness(1.1);
  }
}

.copy {
  width: 40px;
  height: 40px;
  display: grid;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 14px;
  margin: auto;
  border-radius: 40px;
  background: #FFD754;
  box-shadow: -14px -1px 12px #161615;
  transition: background-color .3s;

  &.copied {
    pointer-events: none;
    background: #6f6f6f;
    animation: bouncing .2s linear 1;
  }
}

.copyIcon {
  width: 20px;
  height: 20px;
}

.shareIcon {
  width: 24px;
  height: 24px;
}
