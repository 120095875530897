$font: 'Euclid Square', sans-serif;

/**
 * RESPONSIVE MIXINS
 */
@mixin for-phone-small {
  @media (max-width: 440px) {
    @content;
  }
}

@mixin for-phone-middle {
  @media (max-width: 620px) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 760px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin for-tablet-landscape {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (max-width: 1350px) {
    @content;
  }
}

@mixin for-desktop {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (max-width: 1800px) {
    @content;
  }
}
