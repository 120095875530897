@import '../../../../../assets/sass/variables';

.component {
	width: 100%;
	padding: 26px;
	background: #292928;
	position: relative;
	border-radius: 32px 32px 0 0;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	transition: filter .3s;

	&:hover {
		filter: brightness(1.2);
	}
}

.top {
	display: grid;
	justify-items: center;
	text-align: center;
	gap: 16px;
}

.icon {
	width: 98px;
	height: 98px;
	position: relative;

	&::before {
		width: 100%;
		height: 100%;
		border-radius: 24px;
		overflow: hidden;
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		margin: auto;
		background-repeat: no-repeat;
	}

	&.easy::before, &.medium::before, &.hard::before {
		background-size: 56px;
		background-position: 50%;
	}

	&.easy::before {
		background-color: #E3DABD;
	}

	&.medium::before {
		background-color: #FFD754;
	}

	&.hard::before {
		background-color: #91EC5F;
	}

	&.worldEgg::before, &.plgBet::before {
		background-size: 100%;
	}

	&.worldEgg::before {
		background-image: url(../../../item/assets/images/worldegg.png);
	}

	&.plgBet::before {
		background-image: url(../../../item/assets/images/plgbet.png);
	}

	&.friends::before {
		background-image: url(../../../item/assets/images/friends.svg);
	}

	&.boxes::before {
		background-image: url(../../../item/assets/images/boxes.svg);
	}

	&.wallet::before {
		background-image: url(../../../item/assets/images/wallet.svg);
	}

	&::after {
		width: 36px;
		height: 36px;
		content: '';
		position: absolute;
		bottom: -10px;
		left: -15px;
		border-radius: 36px;
		opacity: 0;
		transform: scale(0);
		transition: opacity .3s, transform .3s;
	}

	&.fail::after {
		opacity: 1;
		transform: scale(1);
		background: #292928 url(../images/fail-icon.svg) 50% no-repeat;
		background-size: 28px;
	}

	&.done::after {
		opacity: 1;
		transform: scale(1);
		background: #292928 url(../images/done-icon.svg) 50% no-repeat;
		background-size: 28px;
	}
}

.text {
	display: grid;
	justify-content: center;
	justify-items: center;
	gap: 8px;
	line-height: 1.2;
}

.title {
	font-size: 18px;
	font-weight: 700;
	color: #fff;
}

.description {
	color: #BAB7AE;
	font-size: 16px;

	strong {
		font-weight: 700;
		color: #FFD754;
	}
}

.buttons {
	margin-top: 24px;
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr auto;
}

.go, .check {
	height: 64px;
	display: grid;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	font-size: 18px;
	font-weight: 700;
	border-radius: 32px;
	transition: filter .3s;

	&:hover {
		filter: brightness(1.1);
	}

	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 32px;
		line-height: 24px;
	}
}

.go {
	color: #000;
	background: linear-gradient(180deg, #71BD14 0%, #C3FF54 100%);

	span {
		background: url(../images/go-icon.svg) 100% 50% no-repeat;
		background-size: 24px;
	}
}

.check {
	color: #C3FF54;
	padding: 0 24px;
	background: linear-gradient(180deg, rgba(113, 189, 20, 0.36) 0%, rgba(195, 255, 84, 0.50) 100%);
	position: relative;

	&::before {
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		border-radius: inherit;
		border: 2px solid rgba(195, 255, 84, 0.12);
		pointer-events: none;
	}

	span {
		background: url(../images/check-icon.svg) 100% 50% no-repeat;
		background-size: 24px;
		transition: opacity .3s;
	}

	&.loading span {
		opacity: 0;
	}

	&.disabled {
		pointer-events: none;
		filter: grayscale(1);
	}
}

.loaderWrapper {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	visibility: hidden;
	opacity: 0;
	transition:
			opacity 0.3s ease,
			visibility 0.3s ease;

	.loading & {
		visibility: visible;
		opacity: 1;
	}
}

.loader {
	div {
		background-color: #C3FF54 !important;
	}
}
