@keyframes ellipsis-1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes ellipsis-2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(12px, 0);
    }
}

@keyframes ellipsis-3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

.loader {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 14px;

    div {
        position: absolute;
        top: 4px;
        width: 6px;
        height: 6px;
        background: #fff;
        border-radius: 50%;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 3px;
            animation: ellipsis-1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 3px;
            animation: ellipsis-2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 15px;
            animation: ellipsis-2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 27px;
            animation: ellipsis-3 0.6s infinite;
        }
    }
}
