@import '../../../../assets/sass/variables';

@keyframes main-light {
	100% {
		opacity: 1;
		transform: translateY(-50px);
	}
}

@keyframes main-egg {
	50% {
		opacity: 1;
	}

	100% {
		opacity: 1;
		transform: translateY(30px) scale(1);
	}
}

@keyframes rocket-flying {
	0% {
		transform: translate(0, 0) rotate(0deg);
	}
	10% {
		transform: translate(-2px, -4px) rotate(-1deg);
	}
	20% {
		transform: translate(2px, 4px) rotate(1deg);
	}
	30% {
		transform: translate(-1px, 4px) rotate(0deg);
	}
	40% {
		transform: translate(1px, -4px) rotate(1deg);
	}
	50% {
		transform: translate(0, 0) rotate(-1deg);
	}
	60% {
		transform: translate(2px, 3px) rotate(1deg);
	}
	70% {
		transform: translate(-2px, -3px) rotate(0deg);
	}
	80% {
		transform: translate(1px, 4px) rotate(-1deg);
	}
	90% {
		transform: translate(-1px, -4px) rotate(1deg);
	}
	100% {
		transform: translate(0, 0) rotate(0deg);
	}
}

.component {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 450px;
	transition: transform .3s;

	&.invite {
		transform: translateY(-60px);
		z-index: 40;
	}
}

.platform {
	width: 100%;
	height: 332px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);

	&::before, &::after {
		width: 100px;
		height: 100%;
		content: '';
		background: linear-gradient(270deg, rgba(11, 11, 11, 0) 0%, rgba(11, 11, 11, 1) 100%);
		position: absolute;
		top: 0;
		opacity: .9;
		z-index: 2;
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
		transform: rotate(180deg);
	}
}

.light {
	width: 100%;
	height: 100%;
	position: absolute;
	background: url(../images/bg.png) 50% 0 no-repeat;
	background-size: 622px;
	left: 0;
	top: 0;

	.main & {
		top: 50px;
		opacity: 0;
		animation: main-light 1.5s cubic-bezier(0.28, 0.07, 0.21, 0.87) 1 forwards;
	}

	.tapped & {
		background: url(../images/tapped-light.png) 50% 0 no-repeat;
		background-size: 622px;
		filter: brightness(1.1);
	}
}

.eggRow {
	width: 290px;
	height: 336px;
	position: absolute;
	left: 0;
	right: 0;
	top: -90px;
	margin: auto;
	z-index: 39;
	transform: scale(1.05);

	@media (max-height: 700px) {
		width: 250px;
		height: 290px;
	}

	.invite & {
		z-index: 40;
	}

	.main & {
		top: -120px;
		opacity: 0;
		animation: main-egg 1.5s cubic-bezier(0.28, 0.07, 0.21, 0.87) 1 forwards;

		@media (max-height: 700px) {
			top: -70px;
		}
	}
}

.egg {
	width: 100%;
	height: 100%;
	background: url(../images/egg.png) no-repeat 0 0;
	background-size: auto 100%;
	position: absolute;
	left: 0;
	top: 0;

	.tapped & {
		background-position: -290px 0;
		transform: scale(0.96);

		@media (max-height: 700px) {
			background-position: -250px 0;
		}
	}
}

.eyes {
	width: 166px;
	height: 72px;
	background: url(../images/eyes.png) no-repeat;
	background-size: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 120px;
	opacity: 0;
	margin: auto;
	transition: opacity .4s, transform .4s;

	&::before, &::after {
		width: 25px;
		height: 25px;
		background: url(../images/eye-inner.png) no-repeat;
		background-size: 100%;
		content: '';
		position: absolute;
		top: 10px;
		transition: opacity .4s, transform .4s;
		opacity: 0;
	}

	.invite &::before, .invite &::after {
		opacity: 1;
		transform: translateY(15px);
	}

	&::before {
		left: 22px;
	}

	&::after {
		right: 22px;
	}

	.invite & {
		opacity: 1;
		transform: translateY(-20px);
	}
}

.cloud {
	width: 130px;
	height: 125px;
	position: absolute;
	right: -25px;
	top: -10px;
	background: url(../images/cloud.png) no-repeat;
	background-size: 100%;
	text-align: center;
	font-size: 26px;
	font-weight: 700;
	color: #000;
	padding-top: 40px;
	transform: scale(.8) rotate(10deg);
	opacity: 0;
	transition: opacity .3s, transform .3s;

	.invite & {
		opacity: 1;
		transform: scale(1) rotate(0deg) translateY(-20px) translateX(20px);
	}
}

.rocketRow {
	animation: rocket-flying 1s cubic-bezier(0.28, 0.07, 0.21, 0.87) infinite;
}

.rocket {
	width: 56px;
	height: 56px;
	content: '';
	position: absolute;
	right: 10px;
	top: 90px;
	background: url(../images/rocket.png) no-repeat;
	background-size: 100%;
	transform: scale(.6) rotate(-20deg);
	opacity: 0;
	transition: opacity .3s, transform .3s;

	.invite & {
		opacity: 1;
		transform: scale(1) rotate(0deg) translateY(-50px) translateX(30px);
	}
}
