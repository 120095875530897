@import '../../../../assets/sass/variables';

.component {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.middle {
  width: 100%;
  margin-top: 20px;
  mask-image: linear-gradient(180deg, #000 90%, transparent);
}

.bottom {
  padding: 0 20px;
}
