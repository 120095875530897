@import '../../../../../../assets/sass/variables';

@keyframes mcap-appearing {
  100% {
    transform: translateY(30px);
    opacity: 1;
  }
}

.component {
  display: grid;
  padding-top: 20px;
  justify-items: center;
  position: relative;
  top: -30px;
  opacity: 0;
  animation: mcap-appearing 1s cubic-bezier(0.28, 0.07, 0.21, 0.87) 1 forwards;
}

.counter {
  min-width: 200px;
  font-weight: 700;
  font-size: 48px;
  color: #fff;
}

.title {
  font-weight: 700;
  color: #AAA28D;
  font-size: 16px;
  padding-right: 24px;
  background: url(../images/title-icon.svg) right 50% no-repeat;
  background-size: 20px;
  transition: filter .3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.4);
  }
}
