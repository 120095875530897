@import '../../../../../assets/sass/variables';

.component {
	display: grid;
	height: 320px;
	padding: 24px;
	border-radius: 24px;
	align-items: end;
	background: rgba(41, 41, 40, 0.40);
	position: relative;
	margin-top: 30px;

	&::before {
		width: 299px;
		height: 290px;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		margin: auto;
		content: '';
		background: url(../images/gift.png) no-repeat;
		background-size: 100%;
	}
}

.row {
	position: relative;

	&.alreadyOpened {
		background: rgba(107, 107, 107, 0.59);
		border: 1px solid #5d5959;
		backdrop-filter: blur(4px);
		text-align: center;
		display: grid;
		border-radius: 24px;
		padding: 24px;
		gap: 4px;
	}
}

.timer {
	width: 100%;
	text-align: center;
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	position: absolute;
	left: 0;
	bottom: 98px;
}

.button {
	width: 100%;
	height: 64px;
	border-radius: 32px;
	padding: 0 44px;
	color: #000;
	font-size: 18px;
	font-weight: 700;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
	display: grid;
	position: relative;
	z-index: 2;
	background: #FFD754;
	transition: filter .3s, background-color .3s;

	&:hover {
		filter: brightness(1.1);
	}

	&.disabled {
		background: #AC9446;
		pointer-events: none;
	}
}

.title {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 34px;
	background: url(../images/button-icon.svg) right 50% no-repeat;
	background-size: 24px;

	.disabled & {
		opacity: .3;
	}
}

.loaderWrapper {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #FFD754;
	border-radius: 8px;
	visibility: hidden;
	opacity: 0;
	transition:
			opacity 0.3s ease,
			visibility 0.3s ease;

	.loading & {
		visibility: visible;
		opacity: 1;
	}
}

.loader {
	div {
		background-color: #272735 !important;
	}
}

.attemptsRow {
	margin-top: 5px;
	text-align: center;
}

.attempts {
	font-weight: 300;
	font-size: 10px;
	text-transform: uppercase;
	color: #9A9685;
	padding-right: 18px;
	background: url(../images/question-icon.svg) 100% 50% no-repeat;
	line-height: 14px;
	background-size: 14px;

	strong {
		font-weight: 700;
		color: #CCC5A7;
	}
}
