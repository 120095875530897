@import '../../../../assets/sass/variables';

@keyframes bouncing {
  25% {
    transform: scale(1.04);
  }

  50% {
    transform: scale(0.98);
  }

  75% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.component {
  width: 100%;
  padding: 44px 26px 26px 26px;
  background: #292928;
  border-radius: 32px 32px 0 0;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: filter .3s;

  &:hover {
    filter: brightness(1.2);
  }
}

.top {
  width: 100%;
  display: grid;
  gap: 8px;
  justify-items: center;
  text-align: center;
}

.title {
  width: 100%;
  padding-top: 112px;
  background: url(../images/icon.png) 50% 0 no-repeat;
  background-size: 96px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

.subtitle {
  color: #BAB7AE;
  font-size: 14px;
  line-height: 1.5;

  strong {
    color: #FFD754;
    font-weight: 600;
  }
}

.form {
  margin-top: 24px;
  display: grid;
  gap: 8px;
}

.field {
  width: 100%;
  display: grid;
  align-items: center;
  height: 67px;
  border-radius: 67px;
  background: #161615;
  color: #FFD754;
  font-size: 15px;
  text-align: center;
  padding: 0 16px;
  overflow: hidden;

  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.button {
  height: 64px;
  border-radius: 55px;
  background: linear-gradient(180deg, #71BD14 0%, #C3FF54 100%);
  display: grid;
  align-items: center;
  text-align: center;
  overflow: hidden;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  transition: filter .3s;

  &:hover {
    filter: brightness(1.1);
  }

  &.confirmed {
    pointer-events: none;
    filter: grayscale(.8);
    animation: bouncing .2s linear 1;
  }

  &.notEnough {
    filter: grayscale(1);
  }
}
