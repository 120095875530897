@import '../../../../../assets/sass/variables';

.component {
  position: relative;
  margin-top: 16px;
}

.field {
  width: 100%;
  height: 54px;
  border-radius: 65px;
  background: #161615;
  font-size: 15px;
  color: #FFD754;
  font-weight: 500;
  display: grid;
  align-items: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 75px 0 16px;

  &::before {
    width: 32px;
    height: 32px;
    content: '';
    position: absolute;
    right: 9px;
    top: 0;
    margin: auto;
    bottom: 0;
    border-radius: 27px;
    background: url(../images/checked.png) no-repeat;
    background-size: 100%;
  }

  .notConnected & {
    padding: 0 150px 0 16px;
    color: #fff;
    font-weight: 400;

    &::before {
      display: none;
    }
  }

  span {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.connect {
  max-width: 135px;
  height: 35px;
  border-radius: 35px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 9px;
  padding: 0 14px;
  margin: auto;
  background: #FFD754;
  display: grid;
  overflow: hidden;
  align-items: center;
  grid-auto-flow: column;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  transition: filter .3s;

  &:hover {
    filter: brightness(1.1);
  }
}

.buttonTitle {
  background: url(../images/lock.svg) right 50% no-repeat;
  background-size: 18px;
  padding-right: 24px;
}

[data-tc-connect-button="true"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
