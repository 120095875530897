@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-Bold.eot');
  src: local('Euclid Square Bold'), local('EuclidSquare-Bold'),
  url('../fonts/EuclidSquare-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-Bold.woff2') format('woff2'),
  url('../fonts/EuclidSquare-Bold.woff') format('woff'),
  url('../fonts/EuclidSquare-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-Regular.eot');
  src: local('Euclid Square Regular'), local('EuclidSquare-Regular'),
  url('../fonts/EuclidSquare-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-Regular.woff2') format('woff2'),
  url('../fonts/EuclidSquare-Regular.woff') format('woff'),
  url('../fonts/EuclidSquare-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-SemiBold.eot');
  src: local('Euclid Square SemiBold'), local('EuclidSquare-SemiBold'),
  url('../fonts/EuclidSquare-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-SemiBold.woff2') format('woff2'),
  url('../fonts/EuclidSquare-SemiBold.woff') format('woff'),
  url('../fonts/EuclidSquare-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-LightItalic.eot');
  src: local('Euclid Square Light Italic'), local('EuclidSquare-LightItalic'),
  url('../fonts/EuclidSquare-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-LightItalic.woff2') format('woff2'),
  url('../fonts/EuclidSquare-LightItalic.woff') format('woff'),
  url('../fonts/EuclidSquare-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/../fonts/EuclidSquare-MediumItalic.eot');
  src: local('Euclid Square Medium Italic'), local('EuclidSquare-MediumItalic'),
  url('../fonts/../fonts/EuclidSquare-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/../fonts/EuclidSquare-MediumItalic.woff2') format('woff2'),
  url('../fonts/../fonts/EuclidSquare-MediumItalic.woff') format('woff'),
  url('../fonts/../fonts/EuclidSquare-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-Medium.eot');
  src: local('Euclid Square Medium'), local('EuclidSquare-Medium'),
  url('../fonts/EuclidSquare-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-Medium.woff2') format('woff2'),
  url('../fonts/EuclidSquare-Medium.woff') format('woff'),
  url('../fonts/EuclidSquare-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-Italic.eot');
  src: local('Euclid Square Italic'), local('EuclidSquare-Italic'),
  url('../fonts/EuclidSquare-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-Italic.woff2') format('woff2'),
  url('../fonts/EuclidSquare-Italic.woff') format('woff'),
  url('../fonts/EuclidSquare-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-Light.eot');
  src: local('Euclid Square Light'), local('EuclidSquare-Light'),
  url('../fonts/EuclidSquare-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-Light.woff2') format('woff2'),
  url('../fonts/EuclidSquare-Light.woff') format('woff'),
  url('../fonts/EuclidSquare-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-SemiBoldItalic.eot');
  src: local('Euclid Square SemiBold Italic'), local('EuclidSquare-SemiBoldItalic'),
  url('../fonts/EuclidSquare-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/EuclidSquare-SemiBoldItalic.woff') format('woff'),
  url('../fonts/EuclidSquare-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('../fonts/EuclidSquare-BoldItalic.eot');
  src: local('Euclid Square Bold Italic'), local('EuclidSquare-BoldItalic'),
  url('../fonts/EuclidSquare-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuclidSquare-BoldItalic.woff2') format('woff2'),
  url('../fonts/EuclidSquare-BoldItalic.woff') format('woff'),
  url('../fonts/EuclidSquare-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

