@import '../../../../../assets/sass/variables';

.component {
  overflow: hidden;

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #62625D;
    border-radius: 6px;
  }
}

.wrapper {
  width: calc(100% - 20px);
  overflow-y: auto;
  padding: 0 7px 0 10px;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

.list {
  display: grid;
  gap: 2px;
  padding-bottom: 12px;
}
