@import '../../../../../assets/sass/variables';

.component {
  height: 44px;
  padding: 0 12px;
  border-radius: 14px;
  background: #21211F;
  display: grid;
  align-items: center;
  gap: 24px;
  overflow: hidden;
  grid-template-columns: 1fr auto;
}

.name {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
}

.eggs {
  color: #FFD754;
  font-size: 16px;
  font-weight: 700;
  min-width: 80px;
}
