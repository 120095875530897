@import '../../../../../assets/sass/variables';

.component {
  width: 100%;
}

.row {
  padding: 40px;
  display: grid;
  align-items: center;
  text-align: center;
  background: #191818;
  width: calc(100% - 40px);
  margin: 0 auto;
  border-radius: 20px;
  border: 1px solid #2b2b2b;
  color: #9f9f9f;
}
