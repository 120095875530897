@import '../../assets/sass/variables';

.Toastify {
  &__toast-container {
    top: 20px;
    right: 20px;
    z-index: 999999;

    @include for-phone-only {
      left: 0;
      width: 100vw;
      margin: 0;
      padding: 15px;
    }

    &--top-right {
      @include for-phone-only {
        top: 0;
        transform: translateX(0);
      }
    }

    @media (width <= 480px) {
      padding: 15px;
    }
  }

  &__toast-theme--colored.Toastify__toast {
    flex-wrap: wrap;
    min-height: auto;
    margin-bottom: 10px;
    padding: 22px 30px;
    color: #eadfe4;
    font-size: 14px;
    font-family: inherit;
    line-height: 140%;
    border-radius: 14px;
    box-shadow: -4px -4px 20px rgb(23 19 23 / 40%);
    backdrop-filter: blur(8px);

    &-body {
      padding: 0;
    }

    @include for-tablet-portrait-up {
      border-radius: 8px;
    }

    @include for-phone-only {
      width: auto;
      margin-bottom: 5px;
      font-size: 12px;
      border-radius: 6px;
    }

    @media (width <= 480px) {
      margin-bottom: 5px;
    }

    &--info {
      background: rgb(197 197 197 / 36%);

      &::after {
        background: rgb(32 32 32 / 40%);
      }
    }

    &--success {
      background: rgb(180 255 0 / 36%);

      &::after {
        background: rgb(219 235 74 / 20%);
      }
    }

    &--warning {
      background: rgb(255 188 0 / 36%);

      &::after {
        background: rgb(64 59 17 / 40%);
      }
    }

    &--error {
      background: rgb(219 126 114 / 36%);

      &::after {
        background: rgb(47 28 33 / 40%);
      }
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 22px;
      left: 0;
      width: calc(100% - 60px);
      height: 4px;
      margin: auto;
      border-radius: 2px;
      content: '';
    }
  }

  &__progress-bar-theme--colored.Toastify__progress-bar {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 4px;
    margin: 14px auto 0;
    border-radius: 2px;

    &--success {
      background: rgb(252 255 100 / 70%);
    }

    &--warning {
      background: rgb(255 196 30 / 70%);
    }

    &--error {
      background: #ff7664;
    }

    &--info {
      background: rgb(255 255 255 / 70%);
    }
  }
}
