@import '../../../../../assets/sass/variables';

.component {
	display: grid;
	justify-content: center;
	justify-items: center;
	padding-top: 24px;
}

.avatar {
	width: 128px;
	height: 128px;
	border-radius: 128px;
	background: rgba(255, 255, 255, .1);
	overflow: hidden;

	img {
		width: 100%;
	}

	* {
		line-height: 1 !important;
	}

	span {
		display: block !important;
	}
}

.title {
	color: #fff;
	font-size: 24px;
	font-weight: 700;
	line-height: 120%;
	margin-top: 16px;
}

.description {
	margin-top: 10px;
	color: #BAB7AE;
	font-size: 14px;
	font-weight: 500;
	line-height: 120%;
}

.goButton {
	width: 100%;
	height: 64px;
	border-radius: 32px;
	padding: 0 44px;
	color: #000;
	font-size: 18px;
	font-weight: 700;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
	display: grid;
	position: relative;
	background: linear-gradient(180deg, #71BD14 0%, #C3FF54 100%);
	transition: filter .3s, width .6s;

	&:hover {
		filter: brightness(1.1);
	}
}

.buttonTitle {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 34px;
	background: url(../images/button-icon.svg) right 50% no-repeat;
	background-size: 24px;
}
