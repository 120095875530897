@import '../../assets/sass/variables';

.component {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: end;
  justify-content: center;
  justify-items: center;
  width: 100vw;
  height: var(--app-height);
  overflow: hidden;

  &.enter {
    opacity: 0;

    .content {
      transform: translateY(20px);
    }
  }

  &.enterActive {
    opacity: 1;
    transition: opacity 0.2s ease;

    .content {
      transform: translateY(0px);
      transition: 0.2s transform;
    }
  }

  &.exit {
    opacity: 1;
  }

  &.exitActive {
    opacity: 0;
    transition: opacity 0.2s ease;

    .content {
      transform: translateY(20px);
      transition: 0.2s transform;
    }
  }
}

.content {
  width: 100%;
}
