html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  height: 100%;
}

a,
button,
input {
  outline: none;
}

*,
::before,
::after {
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

hr {
  overflow: visible;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

summary {
  display: list-item;
}

small {
  font-size: 80%;
}

[hidden],
template {
  display: none;
}

abbr[title] {
  text-decoration: none;
  border-bottom: 1px dotted;
}

a:active,
a:hover {
  outline-width: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

input {
  border-radius: 0;
}

button,
[type='button'],
[type='reset'],
[type='submit'],
[role='button'] {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

[type='search'] {
  outline-offset: -2px;
  appearance: textfield;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  appearance: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button {
  overflow: visible;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:-moz-focusring,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input,
select,
textarea {
  color: inherit;
  background-color: transparent;
  border-style: none;
}

legend {
  display: table;
  max-width: 100%;
  color: inherit;
  white-space: normal;
  border: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  appearance: button;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

ul,
li {
  list-style: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}
