@import './variables';
@import './container';

html, body {
  position: relative;
  background: #000;
  color: #ccc;
}

body::before {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  content: '';
  background: rgba(11, 11, 11, 0.90);
  backdrop-filter: blur(8px);
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility .2s;
}

body.eggcover::before {
  z-index: 41;
}

body.blurred::before {
  opacity: 1;
  visibility: visible;
}

body.blurred::before {
  @include for-desktop {
    opacity: 1;
    visibility: visible;
  }
}

html, body, input, textarea, button {
  font-family: $font;
}

button {
  cursor: pointer;
  background: none;
}

a {
  text-decoration: none;
}

a.default-link {
  transition: color .3s;

  &:hover {
    color: #fff;
  }
}

input::placeholder, textarea::placeholder {
  color: #95A3C8;
  transition: color .3s;
}

input:focus::placeholder, textarea:focus::placeholder {
  color: transparent;
}

