@import '../../../assets/sass/variables';

.component {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 100px 0;

  @include for-tablet-landscape-up {
    padding: 80px 0;
  }

  @include for-tablet-portrait-up {
    padding: 50px 0;
  }

  @include for-phone-only {
    padding: 30px 0;
  }
}
