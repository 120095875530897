@import '../../../../../../assets/sass/variables';

.component {
  min-width: 220px;
  color: #FFD754;
  font-size: 16px;
  margin-top: 12px;
  text-align: center;
  padding-right: 24px;
  background: url(../images/title-icon.svg) right 50% no-repeat;
  background-size: 20px;

  strong {
    font-weight: 700;
  }
}
