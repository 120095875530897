@import '../../../../../assets/sass/variables';

.component {
	height: 50px;
	padding: 0 18px;
	border-radius: 14px;
	background: #212120;
	display: grid;
	align-items: center;
	gap: 24px;
	grid-template-columns: 1fr 150px;
	position: relative;
}

.name {
	font-size: 14px;
	font-weight: 500;
}

.eggs {
	font-size: 14px;
	font-weight: 700;
	text-align: right;
}
