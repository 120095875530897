@import '../../../../../assets/sass/variables';

.component {
	overflow: hidden;
	margin-top: 12px;

	::-webkit-scrollbar-track {
		margin: 25px 0;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #62625D;
		border-radius: 6px;
		margin: 20px 0;
	}
}

.wrapper {
	width: calc(100% - 20px);
	overflow-y: auto;
	padding: 5px 7px 0 10px;
	margin: 0 auto;
	-webkit-overflow-scrolling: touch;
}

.list {
	display: grid;
	gap: 2px;
	padding-bottom: 12px;
}

.item {
	height: 50px;
	padding: 0 18px;
	border-radius: 14px;
	background: #212120;
	display: grid;
	align-items: center;
	gap: 24px;
	grid-template-columns: 1fr 60px 150px;
	position: relative;

	&::before {
		width: 24px;
		height: 24px;
		content: '';
		position: absolute;
		top: -7px;
		left: -9px;
		background-size: 100%;
		background-repeat: no-repeat;
	}

	&:nth-child(1) {
		background: linear-gradient(96deg, #8A6F35 -20.65%, #292928 19.26%);

		&::before {
			background-image: url(../images/first-class.svg);
		}
	}

	&:nth-child(2) {
		background: linear-gradient(96deg, #4C7092 -20.65%, #292928 19.26%);

		&::before {
			background-image: url(../images/second-class.svg);
		}
	}

	&:nth-child(3) {
		background: linear-gradient(96deg, #925C4C -20.65%, #292928 19.26%);

		&::before {
			background-image: url(../images/third-class.svg);
		}
	}
}

.name {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.users {
	padding-left: 20px;
	font-weight: 600;
	color: #BAB7AE;
	font-size: 13px;
	background: url(../images/users.svg) 0 50% no-repeat;
	background-size: 16px;
}

.eggs {
	color: #FFD754;
	font-size: 14px;
	font-weight: 700;
	padding-right: 18px;
	background: url(../images/eggs.png) right 50% no-repeat;
	background-size: 14px;
	text-align: right;
}

.periods {
	display: grid;
	gap: 8px;
	grid-auto-flow: column;
	justify-content: left;
	margin: 0 0 10px 20px;
}

.period {
	height: 32px;
	padding: 0 20px;
	background: #212120;
	transition: color .3s, background-color .3s;
	border-radius: 32px;
	color: #706D67;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: -0.4px;
	text-transform: uppercase;

	&.current {
		background: #BAB7AE;
		color: #000;
		pointer-events: none;
	}
}

.empty {
	padding: 40px;
	display: grid;
	align-items: center;
	text-align: center;
	background: #191818;
	border-radius: 20px;
	border: 1px solid #2b2b2b;
	color: #9f9f9f;
	margin-bottom: 20px;
}
